.c-contact__information {
    padding: rhythm(2) rhythm(1);
    text-align: center;

    .c-heading {
        margin-bottom: rhythm(.75);
    }

    @include breakpoint(large) {
        padding: rhythm(1);
    }
}

.c-contact__list {
    margin-bottom: rhythm(.5);
    text-align: center;
}

.c-contact__list-item {
    @include responsive-text-size('epsilon');
    padding-left: 0;
    color: $color-primary;
}
