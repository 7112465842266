/*----------------------------------------------------------------------------*\
    #BASE IMAGES
\*----------------------------------------------------------------------------*/

/**
 * Fluid images.
 */

img {
    max-width: 100%;
    height: auto;
}


/**
 * Images in `figure` elements.
 */

figure > img { display: block; }
