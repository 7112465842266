.c-cards {

    @include breakpoint(medium) {
        padding: rhythm(2);
    }
}

.c-card--centered {
    margin-bottom: rhythm(2);
    padding-right: rhythm(.5);
    padding-left: rhythm(.5);
    text-align: center;

    .c-card__heading {
        @include responsive-text-size('gamma');
        margin-top: rhythm(.5);
        font-family: $primary-font;
        font-weight: 900;
    }

    .c-card__text {
        @include responsive-text-size('delta');
        margin-top: rhythm(.5);
    }

    @include breakpoint(large) {
        margin-bottom: 0;
    }
}

.c-card--work {
    width: 100%;
    margin-bottom: rhythm(1);
    overflow: hidden;

    &:hover {

        .o-card__image {

            img {
                transform: scale(1.05);
            }
        }

        .o-card__content {
            background-color: $color-secondary;
            color: $color-white;
        }
    }

    .o-card__image {
        position: relative;
        line-height: 0;

        img {
            width: 100%;
            transform: scale(1);
            transition: transform .24s ease-in;
        }

        &::after {
            @include offset(absolute, null, null, 0, 50%);
            @include size(90px, 54px);
            transform: translateX(-50%);
            content: '';
        }
    }

    .o-card__content {
        padding: rhythm(1) rhythm(1.5);
        transition: background-color .24s ease-in, color .24s ease-in;
        background-color: $color-white;
    }

    .o-card__date {
        display: block;
        margin-top: rhythm(.25);
    }

    .o-card__subtitle {
        margin-top: rhythm(.25);
    }

    @include breakpoint(large) {
        margin-top: rhythm(2);

        .o-card__image {

            &::after {
                @include size(55px, 90px);
                top: 50%;
                right: 0;
                left: auto;
                transform: translate(0, -50%);
            }
        }

        .o-card__date { margin-top: 0; }

        .o-card__subtitle { margin-top: rhythm(.5); }
    }
}

.c-card--work--communicatie {

    .o-card__image {

        &::after {
            background-position: -672px -384px;
        }
    }

    @include breakpoint(large) {

        .o-card__image {

            &::after {
                right: 0;
                background-position: -672px -96px;
            }
        }
    }
}

.c-card--work--journalistiek {

    .o-card__image {

        &::after {
            background-position: -672px -288px;
        }
    }

    @include breakpoint(large) {

        .o-card__image {

            &::after {
                right: 0;
                background-position: -672px 0;
            }
        }
    }
}

.c-card--work--web {

    .o-card__image {

        &::after {
            background-position: -672px -480px;
        }
    }

    @include breakpoint(large) {

        .o-card__image {

            &::after {
                right: 0;
                background-position: -672px -192px;
            }
        }
    }
}

.c-card--work--communicatie--reversed {

    .o-card__image {

        &::after {
            background-position: -672px -384px;
        }
    }

    @include breakpoint(large) {

        .o-card__image {

            &::after {
                right: auto;
                left: 0;
                background-position: -768px -96px;
            }
        }
    }
}

.c-card--work--journalistiek--reversed {

    .o-card__image {

        &::after {
            background-position: -672px -288px;
        }
    }

    @include breakpoint(large) {

        .o-card__image {

            &::after {
                right: auto;
                left: 0;
                background-position: -768px 0;
            }
        }
    }
}

.c-card--work--web--reversed {

    .o-card__image {

        &::after {
            background-position: -672px -480px;
        }
    }

    @include breakpoint(large) {

        .o-card__image {

            &::after {
                right: auto;
                left: 0;
                background-position: -768px -192px;
            }
        }
    }
}

.c-card--article {
    margin-top: $grid-gutter-percentage;
    margin-bottom: $grid-gutter-percentage;
    padding: rhythm(1) rhythm(.5);
    background-color: $color-white;
    text-align: center;

    .o-card__date {
        margin-top: rhythm(1);
    }

    .o-card__image {
        margin-top: rhythm(1);
    }

    .o-card__excerpt {
        @include rhythm-padding(1);
        @include responsive-text-size('epsilon');
        padding: rhythm(1);
    }

    .c-btn {
        margin-right: auto;
        margin-left: auto;
    }
}
