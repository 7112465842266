/*----------------------------------------------------------------------------*\
    #BASE COPY
\*----------------------------------------------------------------------------*/

.c-copy {

    /**
     * Set default vertical spacing for all elements:
     * 1. every second, third, etc. element that has not a `.o-grid` class.
     * 2. every element with a `.o-grid` class that is not proceded
     *    by an element with a `.o-grid` class.
     *
     * Based on 'The "Leftover Glue" problem' solution:
     * http://slides.com/heydon/effortless-style/#/22
     */

    > * + *:not(.o-grid), /* 1 */
    > *:not(.o-grid) + .o-grid { /* 2 */
        margin-top: rhythm(1);
    }


    > * {

        &:last-child {
            margin-bottom: rhythm(2);
        }
    }


    /**
     * Set additonal vertical spacing aroundf figure elements:
     * 3. every figure with a `o-figure-image` class that comes direclty
     *    after an element without a `.o-grid` class.
     * 4. every element that has not a `.o-grid` class and is proceded
     *    by a figure element with `.o-figure-image` class.
     */

    > *:not(.o-grid) + figure, /* 3 */
    > figure + *:not(.o-grid) { /* 4 */
        margin-top: rhythm(3);
    }


    /**
    * Reset (decrease) some margins for better typography (proximity)
    * See: http://zellwk.com/blog/wrong-about-vertical-rhythm/
    */

    > *:not(.o-grid) + p {
        margin-top: rhythm(.5) !important;
    }

    > *:not(.o-grid) + ul,
    > *:not(.o-grid) + ol,
    > *:not(.o-grid) + dl {
        margin-top: rhythm(.5) !important;
    }

    > h2 + *:not(.o-grid),
    > h3 + *:not(.o-grid) {
        margin-top: rhythm(.5) !important;
    }

    > h4 + p:not(.o-grid),
    > h5 + p:not(.o-grid),
    > h6 + p:not(.o-grid) {
        margin-top: 0 !important;
    }

    /*------------------------------------------------------------------------*\
    #TEXT MAX WIDTH AND HYPHENATION
\*------------------------------------------------------------------------*/

    > h2,
    > h3,
    > p,
    > ul li,
    > ol li,
    > dl dt,
    > dl dd,
    > time,
    > table {
        margin-right: auto;
        margin-left: auto;
        hyphens: auto;
        @include breakpoint(medium) {
            max-width: 75%;
        }
        @include breakpoint(large) {
            max-width: 60%;
        }
    }

    p {
        @include responsive-text-size('delta');

        a { color: $color-secondary; }

        strong { font-weight: 700; }
    }

    .c-heading {
        @include responsive-text-size('beta');
    }

    .o-figure-image--md {
        display: block;
        margin-right: auto;
        margin-left: auto;

        @include breakpoint(medium) {
            max-width: 75%;
        }
        @include breakpoint(large) {
            max-width: 60%;
        }
    }

    .o-figure-image--lg {
        margin-right: auto;
        margin-left: auto;

        @include breakpoint(medium) {
            max-width: 75%;
        }
    }

    figcaption {
        @include rhythm-padding(1);

        p { @include responsive-text-size('pico'); }
    }

    .c-intro {

        p {
            @include responsive-text-size('beta');
        }

        @include breakpoint(medium) {
            max-width: 75%;
        }

        @include breakpoint(large) {
            padding-top: rhythm(4) !important;
            padding-bottom: rhythm(3) !important;
        }
    }
}
