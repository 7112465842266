/*----------------------------------------------------------------------------*\
    #SETTINGS COLORS
\*----------------------------------------------------------------------------*/

/**
 * Base colors.
 */

$color-black:                   black;
$color-white:                   white;


/**
 * Grays.
 */

$color-gray-darker:             tint($color-black, 5%);
$color-gray-dark:               tint($color-black, 10%);
$color-gray:                    rgb(240, 240, 240);
$color-gray-light:              tint($color-black, 90%);
$color-gray-lighter:            tint($color-black, 95%);
$color-gray-lightest:           rgb(241, 241, 241);


/**
 * Project colors.
 */

$color-primary:                 rgb(5, 47, 57);
$color-secondary:               rgb(7, 118, 143);
$color-third:                   rgb(18, 56, 65);
$color-fourth:                  rgb(193, 211, 210);
$color-fifth:                   rgb(131, 167, 165);

$color-accent:                  rgb(255, 192, 0);

$color-text:                    rgb(12, 17, 18);
