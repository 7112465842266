/*----------------------------------------------------------------------------*\
    #OBJECTS FORMS
\*----------------------------------------------------------------------------*/

// .o-form { }


/* Fieldset */

/**
 * Group sets of form fieldsets in a (ordered) list, e.g.:
 * <form class="o-form">
 *    <fieldset>
 *       <ol class="o-form__fields">
 *          <li class="o-form__item o-form__item--stacked">
 *             <label for="text" class="o-form__label">...</label>
 *             <input id="text" type="text" class="o-form__input">
 *          </li>
 *          <li class="o-form__item o-form__item--stacked">
 *             ...
 *          </li>
 *          <li class="o-form__item o-form__item--stacked">
 *             ...
 *          </li>
 *       </ol>
 * </form>
 */

.o-form__fields {

    .copy & { /* Reset form list within .copy class. */
        margin-left: 0;

        li { list-style: none; }
    }
}

.o-form--inline .c-form__item {
    display: inline-block;
    margin-right: rhythm(1);
}

.o-form__item { margin-bottom: rhythm(1); }

.o-form__label { font-weight: 600; }

.o-form__item--stacked .o-form__label {
    display: block;
    margin-bottom: rhythm(.5);
}

.o-form__input {
    @include responsive-text-size('zeta');
    margin-bottom: rhythm(.75);
    padding: rhythm(.8); /* To keep vertical rhythm it should be smaller than rhythm podding of `.o-form__label`. */
    transition: border .24s ease-in;
    border: 1px solid $color-fourth;
    border-radius: 0;
    background-color: $color-white;
    -webkit-appearance: none; /* Reset iOS inset shadow on inputs. */

    &:focus {
        border: 1px solid $color-primary;
        outline: 0;
    }

    &::-webkit-input-placeholder {
        color: $color-fifth;
    }
}

.c-input__placeholder { color: $color-fifth; }


.o-form__input--full { width: 100%; }


/**
 * Extra help text after input on focus:
 * <li class="o-form__item o-form__item--stacked">
 *    <label class="o-form__label">Label text</label>
 *    <input class="o-form__input"/>
 *    <small class="o-form__helper">Helper text</small>
 * </li>
 */

.o-form__helper {
    display: inline-block;
    visibility: hidden;
}

.o-form__input:active + .o-form__helper,
.o-form__input:focus + .o-form__helper { visibility: visible; }


/**
 * Set optional label like this:
 * <label class="o-form__label">Label text <small class="o-form__optional">(Optional)</small></label>
 */

.o-form__optional { font-weight: 400; }


/**
 * Error handling (inline).
 */

.o-form__helper--error { display: inline-block; }

/**
 * Lists of checkboxes and radio buttons, e.g.:
 * <li class="o-form__item o-form__item--stacked">
 *    <fieldset>
 *       <span class="o-form__label">Radio set</span>
 *       <ul class="o-input-set o-input-set--horizontal">
 *          <li>
 *             <label class="o-input-set__label">
 *                <input type="radio" class="c-radio" name="radioset" value="Option 1"/>
 *                <span>Option 1</span>
 *             </label>
 *          </li>
 *          <li>
 *             ...
 *          </li>
 *       </ul>
 *    </fieldset>
 * </li>
 */

.o-input-set { margin: 0; }


/**
 * Horizontal.
 */

.o-input-set--horizontal > li {
    display: inline-block;
    margin-right: rhythm(1);

    &:last-child { margin-right: 0; }
}


/**
 * Labels of checkboxes/radio buttons.
 */

.o-input-set__label { color: $color-gray; }

.is-error {

    .o-form__input {
        margin-bottom: 0;
    }

    .parsley-errors-list {
        margin-top: rhythm(.25);
        margin-bottom: rhythm(.75);
    }
}

.parsley-errors-list {
    @extend .o-list-plain;
    color: $color-text;

    li {
        @include responsive-text-size('nano');
        color: rgb(223, 33, 33);
    }
}

.c-thankyou {
    display: none;
}
