/*----------------------------------------------------------------------------*\
    #OBJECTS LISTS
\*----------------------------------------------------------------------------*/

/**
 * Plain list (no bullets, etc.).
 */

.o-list-plain {

    > li {
        padding-left: 0;

        &::before {
            display: none;
        }
    }
}
