.c-content {
    @include rhythm-padding(2);

    .o-grid .o-grid__cell {
        text-align: center;
    }

    @include breakpoint(large) {

        .o-grid .o-grid__cell {
            text-align: left;
        }
    }
}

.c-content__image {
    width: 100%;
    margin-top: rhythm(3);
    margin-bottom: rhythm(3);
}

.c-content__wrapper {
    max-width: 40rem;
}

.c-content__heading {
    @include responsive-text-size('mega');
    position: relative;
    padding-bottom: rhythm(.5);
    font-family: $primary-font;
    font-weight: 900;
    text-transform: uppercase;
}

.c-content__text {
    @include responsive-text-size('delta');
    margin-top: rhythm(1);
    color: $color-primary;

    a { color: $color-secondary; }

    strong { font-weight: 700; }
}

.c-content--reverse {

    .c-content__image {
        float: left;
    }
}
