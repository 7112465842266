/*----------------------------------------------------------------------------*\
    #BASE HEADINGS
\*----------------------------------------------------------------------------*/

%heading-settings { letter-spacing: .01em; }

h1,
h2,
h3,
h4,
h5,
h6 { @extend %heading-settings; }

h1 { @include responsive-text-size('alpha'); }

h2 { @include responsive-text-size('beta'); }

h3 { @include responsive-text-size('gamma'); }

h4 { @include responsive-text-size('delta'); }

h5 { @include responsive-text-size('epsilon'); }

h6 { @include responsive-text-size('zeta'); }
