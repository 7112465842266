/*----------------------------------------------------------------------------*\
    #SETTINGS GRID
\*----------------------------------------------------------------------------*/

/**
 * Gutter/spacing percantages.
 */

$grid-gutter-percentage:        6%;     /* set gutter (horizontal) percentage for the layout grid. */
$grid-spacing-percentage:       6%;     /* set vertical spacing percentage for the layout grid. */


/**
 * Gutter/spacing rhythm units.
 */

$grid-gutter-rhythm-unit:       10px;     /* set gutter (horizontal) rhythm unit for the layout grid (e.g. `rhythm(1)`). */
$grid-spacing-rhythm-unit:      10px;     /* set vertical spacing rhythm unit for the layout grid. */
