/*----------------------------------------------------------------------------*\
    #OBJECTS GRID
\*----------------------------------------------------------------------------*/

/**
 * Example HTML:
 * <div class="o-grid">
 *   <div class="o-grid__cell u-1of2--cp"></div>
 *   <div class="o-grid__cell u-1of2--cp"></div>
 *   <div class="o-grid__cell u-1of2--cp"></div>
 *   <div class="o-grid__cell u-1of2--cp"></div>
 * </div>
 *
 * <div class="o-grid o-grid--center">
 *   <div class="o-grid__cell u-1of2--cp"></div>
 *   <div class="o-grid__cell u-1of2--cp"></div>
 * </div>
 */





/*----------------------------------------------------------------------------*\
    #GRID CORE
\*----------------------------------------------------------------------------*/

/**
 * Grid container.
 * Must only contain `.o-grid` or `.o-grid__cell` components as children.
 *
 * 1. Adjustment for child element margins.
 * 2. Remove inter-cell whitespace that appears between `inline-block` child
 *    elements.
 * 3. Ensure consistent default alignment.
 */

.o-grid {
    display: block; /* 1 */
    padding-right: 0;  /* 1 */
    padding-left: 0;  /* 1 */
    font-size: 0; /* 2 */
    text-align: left; /* 3 */

    /**
     * Child `o-grid` object adjustments.
     * Used for more complex fixed-fluid hybrid grids.
     */

    > .o-grid {
        margin-right: 0;
        margin-left: 0;
        overflow: hidden;
    }
}





/*----------------------------------------------------------------------------*\
    #GRID MODIFIERS
\*----------------------------------------------------------------------------*/

/**
 * Horizontally center all grid units.
 */

.o-grid--align-center { text-align: center; }


/**
 * Align horizontally all grid units to the right.
 */

.o-grid--align-right { text-align: right; }


/**
 * Middle-align grid cells.
 */

.o-grid--align-middle > .o-grid__cell { vertical-align: middle; }


/**
 * Bottom-align grid cells.
 */

.o-grid--align-bottom > .o-grid__cell { vertical-align: bottom; }





/*----------------------------------------------------------------------------*\
    #GRID UNITS
\*----------------------------------------------------------------------------*/

/**
 * No explicit width by default. Apply `.unit-x-y` classes.
 *
 * 1. Fundamentals of the non-float grid layout mechanism.
 * 2. Make cells full-width by default.
 * 3. Reset font size change made in `o-grid`.
 * 4. Keeps content correctly aligned with the grid direction.
 * 5. Controls vertical positioning of units.
 */

.o-grid__cell {
    display: inline-block; /* 1 */
    width: 100%; /* 2 */
    margin: 0;
    padding: 0;
    font-size: 1rem; /* 3 */
    text-align: left; /* 4 */
    vertical-align: top; /* 5 */
}





/*----------------------------------------------------------------------------*\
    #GRID-CELL MODIFIERS
\*----------------------------------------------------------------------------*/

/**
 * Horizontally center one unit.
 *
 * Set a specific unit to be horizontally centered. Doesn't affect
 * any other units. Can still contain a child `o-grid` object.
 */

.o-grid__cell--center {
    display: block;
    margin: 0 auto;
}





/*----------------------------------------------------------------------------*\
    #GRID GUTTER
\*----------------------------------------------------------------------------*/

/**
 * Rhythm unit gutter/spacing
 *
 * 1. Removes the `gutter` right
 */

.o-grid--with-gutter {
    margin-right: -$grid-gutter-rhythm-unit; /* 1 */
    margin-left: -$grid-gutter-rhythm-unit; /* 1 */

    > .o-grid__cell {
        padding-right: $grid-gutter-rhythm-unit;
        padding-left: $grid-gutter-rhythm-unit;
    }
}


/**
 * Percentage gutter/spacing.
 *
 * 1. Removes the `gutter` right.
 */

.o-grid--with-gutter-percentage {
    margin-right: -.5 * $grid-gutter-percentage; /* 1 */
    margin-left: -.5 * $grid-gutter-percentage; /* 1 */

    > .o-grid__cell {
        padding-right: .5 * $grid-gutter-percentage;
        padding-left: .5 * $grid-gutter-percentage;
    }
}
