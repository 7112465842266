/*----------------------------------------------------------------------------*\
    #BASE LISTS
\*----------------------------------------------------------------------------*/

ul {
    counter-reset: list;

    li {
        display: list-item;
        position: relative;
        padding-left: rhythm(1);

        &::before {
            @include offset(absolute, null, null, null, 0);
            content: '•';
        }

        > ul,
        > ol { margin-left: rhythm(1); }
    }
}

ol {

    > li {

        &::before {
            margin-right: rhythm(1);
            content: counter(list) '.'; /* Display the counter */
            counter-increment: list; /* Increment the section counter*/
        }
    }
}
