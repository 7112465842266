/*----------------------------------------------------------------------------*\
    #TOOLS FUNCTIONS
\*----------------------------------------------------------------------------*/

/**
 * FUNCTIONS
 *
 * Breakpoints..................................................................
 * Color schemer................................................................
 * Paths........................................................................
 * Pull viewport................................................................
 * Rhythm.......................................................................
 * Strip units..................................................................
 * Typography...................................................................
 */





/*----------------------------------------------------------------------------*\
    #FUNCTIONS BREAKPOINTS
\*----------------------------------------------------------------------------*/

@function breakpoints-map($breakpoint-name) {
    @return map-get($breakpoints, $breakpoint-name);
}

@function breakpoint-value-for($breakpoint-name) {
    $breakpoints-map: breakpoints-map($breakpoint-name);
    @return map-get($breakpoints-map, 'break-value');
}





/*----------------------------------------------------------------------------*\
    #FUNCTIONS COLOR SCHEMER
\*----------------------------------------------------------------------------*/

/**
 * Tint/Shade
 *
 * Tint = Add percentage of white to a color.
 * Shade = Add percentage of black to a color.
 */

@function tint($color, $percent) {
    @return mix($color-white, $color, $percent);
}

@function shade($color, $percent) {
    @return mix($color-black, $color, $percent);
}





/*----------------------------------------------------------------------------*\
    #FUNCTIONS PATHS
\*----------------------------------------------------------------------------*/

@function img-url($filename) {
    @return url(/assets/images/#{$filename});
}





/*----------------------------------------------------------------------------*\
    #FUNCTIONS PULL VIEWPORT
\*----------------------------------------------------------------------------*/

@function pull-viewport($viewport-padding) {
    $viewport-width: 100;
    $viewport-padding: strip-units($viewport-padding);

    $viewport-padding-perc: ((100-($viewport-padding * 2)) / 100);
    $content-width: ($viewport-width * $viewport-padding-perc);
    $pull-viewport-width: $viewport-width / $content-width;
    $pull-viewport-perc: $pull-viewport-width;

    @return $pull-viewport-perc * $viewport-padding * 1%;
}





/*----------------------------------------------------------------------------*\
    #FUNCTIONS VERTICAL RHYTHM
\*----------------------------------------------------------------------------*/

@function rhythm($lines: 1) {
    $text-properties: text-properties-for('base', 'small');
    $line-height: map-get($text-properties, 'line-height');

    @if $lines == 0 {
        @return 0; /* If number of lines is 0, output unitless value */
    } @else {
        @return $line-height * $lines + rem;
    }
}





/*----------------------------------------------------------------------------*\
    #FUNCTIONS STRIP UNITS (used in pullviewport function)
\*----------------------------------------------------------------------------*/

@function strip-units($number) {
    @return $number / ($number * 0 + 1);
}





/*----------------------------------------------------------------------------*\
    #FUNCTIONS TYPOGRAPHY
\*----------------------------------------------------------------------------*/

/**
 * Base font size.
 */

@function base-font-size-for($breakpoint-name) {
    $breakpoints-map: breakpoints-map($breakpoint-name);
    @return map-get($breakpoints-map, 'base-font-size');
}


/**
 * Text properties.
 * 1. Retutn explicitly set line-height (in setting.typpgraphy.scss).
 * 2. Calculate vertical rhythm line-height (https://github.com/ianrose/typesettings).
 */

@function text-breakpoints-for($text-size-name) {
    @return map-get($text-sizing, $text-size-name);
}

@function text-properties-for($text-size-name, $breakpoint-name) {
    $text-breakpoints-map: text-breakpoints-for($text-size-name);
    @return map-get($text-breakpoints-map, $breakpoint-name);
}

@function text-size($text-size-name, $default-breakpoint-name: 'small', $lines: 1) {
    $base-text-properties: text-properties-for('base', 'small');
    $base-text-size: map-get($base-text-properties, 'font-size');
    $text-properties: text-properties-for($text-size-name, $default-breakpoint-name);
    $text-size: map-get($text-properties, 'font-size');
    @return $text-size / $base-text-size + rem;
}

@function text-line-height($text-size-name, $default-breakpoint-name: 'small', $lines: 1) {
    $base-text-properties: text-properties-for('base', 'small');
    $base-text-size: map-get($base-text-properties, 'font-size');
    $base-line-height: map-get($base-text-properties, 'line-height');
    $text-properties: text-properties-for($text-size-name, $default-breakpoint-name);
    $text-size: map-get($text-properties, 'font-size');
    $line-height: map-get($text-properties, 'line-height');

    @if $line-height != null {

        @return $line-height;/* 1 */
    } @else {

        @return (ceil($text-size / ($base-line-height * $base-text-size)) * ($base-line-height * $base-text-size) * $lines) / $text-size; /* 2 */
    }
}
