/*----------------------------------------------------------------------------*\
    #COMPONENTS CHECKBOX
\*----------------------------------------------------------------------------*/

input[type=checkbox] { display: none; }

.c-form__label--checkbox {
    display: inline-block;
    position: relative;
    padding-left: rhythm(1);
    line-height: 1;
    cursor: pointer;

    &::before {
        @include square(20px);
        @include offset(absolute, 0, null, null, 0);
        border: 1px solid $color-black;
        background-color: $color-white;
        content: '';
    }
}

input[type=checkbox]:checked + .c-form__label--checkbox::before {
    text-align: center;
    content: '\2713';
}
