/*----------------------------------------------------------------------------*\
    #COMPONENTS HEADER
\*----------------------------------------------------------------------------*/

.c-header {
    @include offset(fixed, 0, null, null, 0);
    @include size(100%, 70px);
    transition: background-color .24s ease-in;
    border-bottom: 1px solid rgba($color-white, .2);
    background-color: $color-primary;
    z-index: $layer-2;

    &.is-scrolled {
        background-color: $color-primary;
    }

    img {
        @include offset(absolute, 50%, null, null, null);
        transform: translateY(-50%);
    }

    @include breakpoint(large) {
        height: 86px;
        background-color: transparent;
    }
}
