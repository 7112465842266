/*----------------------------------------------------------------------------*\
    #SETTINGS TYPOGRAPHY
\*----------------------------------------------------------------------------*/

/**
 * Font stacks.
 */

$sans-font:                     'Helvetica Neue', 'HelveticaNeue', Helvetica, Arial, ArialMT, sans-serif;
$serif-font:                    'Lucida Bright', 'LucidaBright', Georgia, serif;
$mono-font:                     Monaco, 'Courier New', 'CourierNewPSMT', Courier, monospace, serif;
$heading-font:                  'Helvetica Neue Condensed Bold', 'HelveticaNeue-CondensedBold', 'Arial Narrow Bold', 'ArialNarrow-Bold', 'Arial Narrow', 'ArialNarrow', 'Helvetica Neue', 'HelveticaNeue', Helvetica, Arial, sans-serif;


$primary-font:                  Open Sans, $sans-font;
$secondary-font:                Roboto, $sans-font;

/**
 * Text max-width.
 */

$text-max-width:                40rem;


/**
 * Text sizing.
 *
 * If no line-height at all is explicitly set, a vertical rhythm line-height is
 * calculated and maintained automatically! But if a line-height is explicitly
 * set (out out-commented), vertical rhythm will break for that speceific
 * breakpoint!
 *
 * IMPORTANT!
 * base:                                // <= base text size name
 *      small:                          // <= default breakpoint (mobile first!)
 *           font-size:          1rem,  // <= base font size (never change!)
 *           line-height:        1.375  // <= base line height (never out-comment!)
 *                                      // <= no need to (pointless) add more base breakpoints!
 */

$text-sizing: (
    pico: (
        small: (
            font-size:          .813rem,
            line-height:        1.307
        )
    ),
    nano: (
        small: (
            font-size:          .875rem,
            line-height:        1.357
        )
    ),
    zeta: (
        small: (
            font-size:          .938rem,
            line-height:        1.333
        )
    ),
    base: (
        small: (
            font-size:          1rem,
            line-height:        1.375
        )
    ),
    epsilon: (
        small: (
            font-size:          1.125rem,
            line-height:        1.333
        )
    ),
    delta: (
        small: (
            font-size:          1.125rem,
            line-height:        1.7
        ),
    ),
    gamma: (
        small: (
            font-size:          1.25rem,
            line-height:        1.6
        ),
    ),
    beta: (
        small: (
            font-size:          1.375rem,
            line-height:        1.363
        ),
    ),
    alpha: (
        small: (
            font-size:          1.75rem,
            line-height:        1.143
        ),
    ),
    mega: (
        small: (
            font-size:          2rem,
            line-height:        1.344
        ),
    ),
    giga: (
        small: (
            font-size:          2rem,
            line-height:        1.375
        ),
        compact: (
            font-size:          2.5rem,
            line-height:        1.375
        ),
    ),
    tera: (
        small: (
            font-size:          2.5rem,
            line-height:        1.176
        ),
        compact: (
            font-size:          3.188rem,
            line-height:        1.176
        ),
    ),
);
