/*----------------------------------------------------------------------------*\
    #COMPONENTS HAMBURGER
\*----------------------------------------------------------------------------*/

.c-hamburger {
    @include square(50px);
    @include offset(absolute, 50%, $contain-percentage, null, null);
    display: block;
    transform: translateY(-50%);
    outline: 0;
    background-color: transparent;
    z-index: $layer-1;

    @include breakpoint(large) {
        display: none;
    }

    &:focus { outline: 0; }

    span,
    span::before,
    span::after {
        @include offset(absolute, null, 0, null, null);
        @include size(28px, 2px);
        display: block;
        transition: all .32s ease-in;
        background-color: $color-white;
    }

    span { top: 22px; }

    span::before,
    span::after { content: ''; }

    span::before { top: -8px; }

    span::after { top: 8px; }
}

.c-hamburger.is-open {
    display: block;

    span { transform: rotate(-45deg); }

    span::after {
        top: 0;
        transform: rotate(90deg);
    }

    span::before { opacity: 0; }
}
