/*----------------------------------------------------------------------------*\
    #COMPONENTS FOOTER
\*----------------------------------------------------------------------------*/

.c-footer {
    font-family: $secondary-font;

    a {
        transition: color .24s ease-in;
    }

    a:hover {
        color: $color-secondary;
    }
}

.c-footer__bottom {
    margin-top: rhythm(1);
    padding-top: rhythm(.75);
    border-top: 1px solid $color-white;

    span {
        @include responsive-text-size('pico');
        display: block;
        margin-top: rhythm(1);
        text-align: center;
    }

    .c-socials {
        margin-top: rhythm(1.5);
        text-align: center;

        .c-social {
            margin-right: rhythm(.75);
        }
    }

    @include breakpoint(wide) {

        span {
            margin-top: 0;
        }

        .c-socials {
            margin-top: 0;
        }
    }
}

.c-footer__header {
    margin-bottom: rhythm(.5);
}
