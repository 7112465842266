/*----------------------------------------------------------------------------*\
    #VENDORS BOX-SIZING
\*----------------------------------------------------------------------------*/

/**
 * Apply a natural box layout model to all elements, but allowing components to
 * change. http://www.paulirish.com/2012/box-sizing-border-box-ftw/
 */

html { box-sizing: border-box; }

*,
*::before,
*::after { box-sizing: inherit; }
