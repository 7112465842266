/*----------------------------------------------------------------------------*\
    #BASE FORMS
\*----------------------------------------------------------------------------*/

// form { }

fieldset {
    margin: 0;
    padding: 0;
    border: 0;
}

label,
input,
textarea,
select {
    display: inline-block;
    vertical-align: middle;
}

// input { }

input[type='search'] { box-sizing: border-box; }
