.o-panorama {
    position: relative;
    height: 100%;
}

.o-panorama__image {
    position: absolute;
    width: 100%;
    height: 100%;
}
