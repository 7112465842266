.c-list--work {
    position: relative;
    padding: rhythm(1);
    background-color: white;

    @include breakpoint(wide) {

        &::after {
            position: absolute;
            top: 0;
            left: 50%;
            width: 20px;
            height: 100%;
            margin-left: -10px;
            background-color: $color-primary;
            content: '';
            z-index: $layer-1;
        }
    }
}

.c-list__item {
    @include rhythm-padding(.5);
    position: relative;
    transition: background-color .24s ease-in;

    &:hover {
        background-color: $color-gray-lightest;
    }

    &::before {
        display: none;
        content: '';
    }

    .c-list__heading {
        display: inline-block;
        margin-right: rhythm(.5);
        font-family: $primary-font;
        font-weight: 900;
    }
}
