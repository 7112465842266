/*----------------------------------------------------------------------------*\
    #COMPONENTS BACKGROUNDS
\*----------------------------------------------------------------------------*/

.c-panorama--logo::after,
.c-card--work .o-card__image::after,
.c-work::before,
.c-social::after,
.c-list__item::before {
    background-image: img-url('../../wp-content/themes/harms-communicatie/assets/images/assets.svg');
}
