/*----------------------------------------------------------------------------*\
    #BASE QUOTES
\*----------------------------------------------------------------------------*/

q {
    quotes: none;

    &::before { content: '“test'; }

    &::after { content: '”'; }
}

blockquote {
    quotes: none;

    p {
        display: inline;

        &::before { content: '“'; }

        &::after { content: '”'; }
    }

    figcaption {

        &::before { content: '— '; }
    }
}
