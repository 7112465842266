/*----------------------------------------------------------------------------*\
    #OBJECTS MEDIA OBJECT
\*----------------------------------------------------------------------------*/

/**
 * Place any image- and text-like content side-by-side, as per:
 * http://j.mp/1iqV00F
 */

.o-media {
    @include clearfix();
    display: block;
}

.o-media__image {
    margin-right: rhythm(1);
    float: left;

    > img { display: block; }
}

.o-media__body {
    overflow: hidden;

    &,
    > :last-child { margin-bottom: 0; }
}


/* Reversed image location (right instead of left). */

.o-media--reverse {

    > .o-media__image {
        margin-left: rhythm(1);
        float: right;
    }
}
