/*----------------------------------------------------------------------------*\
    #OBJECTS CONTAIN
\*----------------------------------------------------------------------------*/

.o-contain {
    max-width: 1480px;
    margin-right: auto;
    margin-left: auto;
}

.o-contain-padding {
    padding-right: $contain-percentage;
    padding-left: $contain-percentage;
}

.o-contain-padding--small {
    padding-right: $contain-percentage * .5;
    padding-left: $contain-percentage * .5;
}
