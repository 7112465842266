/*----------------------------------------------------------------------------*\
    #VENDORS WEBFONT LOADING
\*----------------------------------------------------------------------------*/

/**
 * Font events for web fonts loaded with Typekit and/or Google's
 * WebFont Loader to control the Flash of Unstyled Text (FOUT).
 */

.wf-loading {
    opacity: 0;
    visibility: hidden; /* IE7/8 */
}

.wf-active {
    transition: opacity .24s ease-in-out;
    opacity: 1;
    visibility: visible; /* IE7/8 */
}

.wf-inactive {
    opacity: 1;
    visibility: visible; /* IE7/8 */
}
