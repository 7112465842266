/*----------------------------------------------------------------------------*\
    #COMPONENTS BUTTONS
\*----------------------------------------------------------------------------*/

/**
 * 1. To remove rounded corners in Safari on iOS 5+.
 * 2. For anchor button to behave nicely.
 */


.c-btn {
    display: block;
    position: relative;
    width: 100%;
    max-width: 240px;
    padding-top: rhythm(.75);
    padding-bottom: rhythm(.75);
    transition: border .24s ease-in, background-color .24s ease-in, color .24s ease-in;
    border: 0;
    border-radius: 0; /* 1 */
    background: $color-primary;
    color: $color-white;
    line-height: inherit; /* 2 */
    text-align: center;
    box-shadow: inset 0 0 0 -1px transparent;
    cursor: pointer;

    &:hover {
        outline: 0;
        background-color: $color-white;
        color: $color-primary;
        text-decoration: none;
        box-shadow: inset 0 0 0 1px $color-primary;
    }

    &:focus {
        outline: 0;
    }
}

.c-btn--secondary {
    background-color: $color-secondary;

    &:hover {
        color: $color-secondary;
        box-shadow: inset 0 0 0 1px $color-secondary;
    }
}


/**
 * Button size modifiers.
 */

.c-btn--sm {
    margin-bottom: rhythm(.5);
    padding: rhythm(.25);
}

.c-btn--lg {
    margin-bottom: rhythm(.5);
    padding: rhythm(.75) rhythm(1);
}

.c-btn--full {
    display: block;
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    text-align: center;
}

.c-btn--centered {
    margin-right: auto;
    margin-left: auto;
}
