.c-intro {
    @include rhythm-padding(3);
    max-width: 50rem;
    margin-right: auto;
    margin-left: auto;
    text-align: center;

    .o-grid .o-grid__cell {
        text-align: center;
    }

    @include breakpoint(large) {

        .o-grid .o-grid__cell {
            text-align: left;
        }
    }
}

.c-intro__text {
    @include responsive-text-size('gamma');
    margin-top: rhythm(1);
    color: $color-primary;
}

.c-intro__image {
    margin-bottom: rhythm(1.5);
}

.c-intro--large {
    max-width: 60rem;
    text-align: center;

    .o-grid .o-grid__cell {
        text-align: center;
    }
}

.c-intro--not-centered {

    .o-grid .o-grid__cell {
        text-align: center;
    }

    @include breakpoint(large) {

        .o-grid .o-grid__cell {
            text-align: left;
        }
    }
}
