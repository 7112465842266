.c-heading {
    @include responsive-text-size('mega');
    margin-bottom: rhythm(.75);
    font-family: $primary-font;
    font-weight: 900;
    text-transform: uppercase;
}

.c-heading--underline {
    position: relative;
    padding-bottom: rhythm(.5);

    &::after {
        @include offset(absolute, null, null, 0, 50%);
        @include size(86px, 1px);
        margin-left: -43px;
        background-color: black;
        content: '';
    }
}
