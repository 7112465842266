.o-card {
    position: relative;
}

.o-card__image {
    @include square(100%);
}

.o-card__content {
    position: relative;
}

.o-card__heading {
    @include responsive-text-size('base');
    font-family: $primary-font;
    font-weight: 900;
    text-transform: uppercase;
}

.o-card__subtitle {
    @include responsive-text-size('base');
    font-family: $primary-font;
    font-weight: 400;
}

.o-card__date {
    @include responsive-text-size('base');
    font-family: $primary-font;
    font-weight: 400;
}
